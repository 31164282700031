/*
* Asset Builder has a quirk that allows custom scripts
* to load before Bower scripts during concatenation.
* To get around this, use one of the following methods.
*
* IIFE
* (function(){ code goes here }());
*
* Self-executing anonymous function
* var foo = function() { code goes here };
*
* document.ready ($)
* $(document).ready(function() { code goes here });
* $(function() { code goes here });
*
* window.onload (Javascript)
* window.onload = function() { code goes here };
*
*ss
*
*/


jQuery(function($) {
  if($(window).width()>769) {
    $('a[data-toggle="dropdown"]:not([href=""])').off('click.namespace').on('click.namespace', function(e) {
      if ($(this).parent().is('.show, .open'))
      window.open($(this).attr(
        'href'), $(this).attr('target') || '_self');
      });
    };

    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
    });

    $('#myCollapsible').on('show.bs.collapse', function () {
      // do something…
    });

    // ScrollTo
    $('a.scrollTo').on('click', function(e) {
      var scrollTarget = $(this).prop('href');
      scrollTarget = scrollTarget.substring(scrollTarget.indexOf('#'));
      if (undefined == scrollTarget || scrollTarget == '' || scrollTarget == '#'){
        var scrollTarget = $(this).data('scroll');
      }
      var defaultAnchorOffset = 0;
      var anchorOffset = $(scrollTarget).data('anchor-offset');
      if (!anchorOffset)
      anchorOffset = defaultAnchorOffset;
      $('html, body').delay( 100 ).animate({
        scrollTop: $(scrollTarget).offset().top - anchorOffset
      }, 1000);
      //$('nav ul.nav li:hover ul').css('top: -4000px;');
      e.preventDefault();
    });

    // Carousel
    function SplitCarousel(selector,slidesXL,slidesMD,slidesSM){

      this.selector = selector;
      this.slidesXL = slidesXL;
      this.slidesMD = slidesMD;
      this.slidesSM = slidesSM;

      function activeSlide(selector){
        // Get Active slide
        function getActiveSlide(selector){
          var activeSlide = $(selector+' .carousel-item.active').index()+1;
          $(selector+' .carousel-slide-active').text(activeSlide);
        }
        $(selector).on('slid.bs.carousel', function () {
          getActiveSlide(selector);
        });
        getActiveSlide(selector);
      }

      // What is the last Slide Index
      function getLastSlide(selector){
        // What is the last Slide
        lastSlide = $(selector+' .carousel-item').last().index() + 1;
        // Set Max Slide Counter
        $(selector+' .carousel-slide-max').text(lastSlide);
      }

      // Init the Maxed Slide Carousel
      function initCarousel(selector, slidesXL){
        // Identify old Items
        $(selector + ' .carousel-item').addClass('remove');

        // Create new Items
        var arr = $(selector + ' .service-item').map(function() {
          return $(this);
        }).get();

        // Set the Amount of Items
        var itemsLength = arr.length;
        var itemsPerSlide = slidesXL; // slidesXL

        var itemsFactor = itemsLength / itemsPerSlide;

        // Build HTML Structur to be filled
        function buildHTML(){
          for (var i = 0; i < itemsFactor; i++) {
            $(selector).attr('data-init', 'true');
            $(selector + ' .carousel-inner').append('<div class="carousel-item"><div class="row"></div></div>');
          }
        }
        buildHTML();

        // Remove old Items
        $(selector + ' .remove').remove();

        $(arr).each(function(k) {
          $(this).appendTo(selector + ' .carousel-item:eq('+Math.floor(k/itemsPerSlide)+') .row').attr('data-two',k);
        });

        // Set First new Item "active"
        $(selector + ' .carousel-item').first().addClass('active');
        activeSlide(selector);
        console.log('Init Carousel '+selector+'. Yay.');
      }

      // This splits the Carousel to its Single Items
      function splitTheCarousel(selector, slidesXL, slidesMD, slidesSM ) {
        // Identify old Items
        $(selector+' .carousel-item').addClass('remove');

        // Create new Items
        var arr = $(selector+' .service-item').map(function() {
          return $(this);
        }).get();

        // Set the itemsPerSlide per viewport
        if ( $(window).width() <= 992 && $(window).width() >= 768 ) {
          var itemsPerSlide = slidesMD;
        } else if ( $(window).width() <= 768 ) {
          var itemsPerSlide = slidesSM;
        } else {
          var itemsPerSlide = slidesXL;
        }

        // Set the Amount of Items
        var itemsLength = arr.length;
        var itemsFactor = itemsLength / itemsPerSlide;

        // Build HTML Structur to be filled
        function buildHTML(){
          for (var i = 0; i < itemsFactor; i++) {
            $(selector).attr('data-splitted', 'true');
            $(selector).attr('data-init', 'false');
            $(selector+' .carousel-inner').append('<div class="carousel-item"><div class="row"></div></div>');
          }
        }
        buildHTML();

        // Remove old Items
        $(selector+' .remove').remove();

        $(arr).each(function(k) {
          $(this).appendTo(selector+' .carousel-item:eq('+Math.floor(k/itemsPerSlide)+') .row').attr('data-two',k);
        });

        // Set First new Item "active"
        $(selector+' .carousel-item').first().addClass('active');
        console.log('Split Carousel '+selector+'. Yay.');
      }

      // Merge carousel
      function mergeCarousel(selector, slidesXL){
        if (  $(selector).attr('data-init') !== 'true') {

          // Identify old Items
          $(selector+' .carousel-item').addClass('remove');

          // Create new Items
          var arr = $(selector+' .service-item').map(function() {
            return $(this);
          }).get();

          // Set the Amount of Items
          var itemsPerSlide = slidesXL;
          var itemsLength = arr.length;
          var itemsFactor = itemsLength / itemsPerSlide;

          // Build HTML Structur to be filled
          function buildHTML(){
            for (var i = 0; i < itemsFactor; i++) {
              $(selector).attr('data-splitted', 'true');
              $(selector).attr('data-init', 'false');
              $(selector+' .carousel-inner').append('<div class="carousel-item"><div class="row"></div></div>');
            }
          }
          buildHTML();

          // Remove old Items
          $(selector+' .remove').remove();

          $(arr).each(function(k) {
            $(this).appendTo(selector+' .carousel-item:eq('+Math.floor(k/itemsPerSlide)+') .row').attr('data-two',k);
          });

          // Set First new Item "active"
          $(selector+' .carousel-item').first().addClass('active');

          console.log('Merge Carousel '+selector+' back.');
        }

      }

      // Set eventhandler
      $(window).on('resize.carousel', function() {
        // Re-Merge Carousel on Resize
        if ($(window).width() >= 993 && $(selector).attr('data-splitted') === 'true' ) {
          mergeCarousel(selector, slidesXL);
          activeSlide(selector);
          getLastSlide(selector);
        } else if ($(window).width() >= 993) {
          initCarousel(selector, slidesXL);
        }
        // When the Display is smaller than xxx Pixel, the Carousel will be splitted
        if ($(window).width() < 993) {
          initCarousel(selector, slidesXL);
          splitTheCarousel(selector, slidesXL, slidesMD, slidesSM);
          activeSlide(selector);
          getLastSlide(selector);
        }
      }).resize();

    }

    // Init Carousels
    var serviceCarousel = new SplitCarousel('#services-slider', 12,4,1);
    var behandlungCarousel = new SplitCarousel('#behandlung-slider', 3,2,1);

    $('.carousel').carousel({
      interval: false
    })
  });
